<template>
  <div class="all-fee-students-page">
    <v-row justify="center">
      <v-col md="6">
        <!-- main fee accounts list -->
        <intract-smart-list
          class="mt-4"
          ref="all-fee-students-smart-list"
          paginated
          :endpoint="feeStudentsEndpoint"
          :filter-fields="filterFields"
          :item-options="{
            title: 'user.full_name',
            avatar: 'user.image',
            subtitle: 'user.username',
            handleClick: (item) =>
              $router.push({
                name: 'StudentFeeDetails',
                params: { studentId: item.user.id },
              }),
          }"
        >
          <template v-slot:list-item-content="{ item: feeDetail }">
            <v-list-item-content>
              <v-list-item-title>{{
                feeDetail.user.full_name
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                feeDetail.user.username
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                feeDetail.user.room.sections
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-slot:list-item-right="{ item: studentFeeAccount }">
            <v-list-item-icon class="align-self-center ma-0">
              <div class="text-center">
                <p class="ma-0 pa-0 text-caption">Due on</p>
                <p
                  class="ma-0 pa-0 text-caption font-weight-bold"
                  v-if="studentFeeAccount.due_date != null"
                >
                  <span
                    :class="
                      moment(studentFeeAccount.due_date).isAfter(moment())
                        ? 'primary--text'
                        : 'red--text'
                    "
                    >{{ moment(studentFeeAccount.due_date).format("ll") }}</span
                  >
                </p>
                <p
                  class="ma-0 pa-0 text-caption font-weight-bold red--text"
                  v-else
                >
                  Not Set
                </p>
              </div>
              <v-btn
                icon
                color="primary"
                v-if="currentUser.is_admin"
                @click.stop="
                  createEditFeeDetailsSheet.editId = studentFeeAccount.user.id;
                  createEditFeeDetailsSheet.visible = true;
                "
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
            </v-list-item-icon>
          </template>
          <template v-slot:custom-filter--status>
            <v-select
              v-model="status"
              ref="select-filter--payment-status"
              :items="filterFields.status.items"
              small-chips
              deletable-chips
              single-line
              solo
              color="primary"
              label="Filter by Payment Status"
              hide-details
              background-color="transparent"
              item-value="value"
              item-text="title"
              class="rounded-input"
              @change="$refs['select-filter--payment-status'].blur()"
            ></v-select>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
    <!-- student fee details edit sheet -->
    <intract-create-edit-sheet
      key="fee-details-create-edit-sheet"
      ref="fee-details-create-edit-sheet"
      :title="
        createEditFeeDetailsSheet.editId
          ? 'Edit Fee Details'
          : 'Create Fee Details'
      "
      description="Manage your students' fee details with just few clicks"
      persistent
      :visible="createEditFeeDetailsSheet.visible"
      @close="
        createEditFeeDetailsSheet.selectedUser = null;
        createEditFeeDetailsSheet.visible = false;
        createEditFeeDetailsSheet.editId = null;
      "
      :fields="formFields"
      :endpoint="endpoints.studentFeesDetailViewSet"
      :edit-id="createEditFeeDetailsSheet.editId"
      :data-object="createEditFeeDetailsSheet.obj"
      :create-success-message="createEditFeeDetailsSheet.createSuccessMessage"
      :edit-success-message="createEditFeeDetailsSheet.editSuccessMessage"
      :getObject="finalOperationsOnFormObject"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (createEditFeeDetailsSheet.obj = obj)"
    >
      <template v-slot:custom-field--user="{ obj }">
        <v-text-field
          :required="true"
          :hint="formFields.user.helper"
          persistent-hint
          outlined
          type="text"
          hide-details="auto"
          :value="obj.user ? obj.user.full_name : ''"
          readonly
          :disabled="createEditFeeDetailsSheet.editId != null"
          @click="showUserSelectionSheet = true"
        >
          <template #label>
            {{ formFields.user.label
            }}<span class="red--text"><strong> * </strong></span>
          </template>
        </v-text-field>
        <intract-list-sheet
          title="Select Student"
          :description="formFields.user.helper"
          :visible="showUserSelectionSheet"
          @close="showUserSelectionSheet = false"
        >
          <template v-slot:list>
            <intract-smart-list
              class="mt-2"
              :endpoint="studentsWithoutFeeDetailsEndpoint"
              paginated
              :item-options="{
                title: 'full_name',
                avatar: 'image',
                handleClick: (student) =>
                  handleStudentSelectionClick(student, obj),
              }"
            >
              <template v-slot:list-item-content="{ item: student }">
                <v-list-item-content>
                  <v-list-item-title>{{ student.full_name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    student.username
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    student.room.sections
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </intract-smart-list>
          </template>
        </intract-list-sheet>
      </template>
    </intract-create-edit-sheet>
    <!-- fee details create fab -->
    <transition name="slide-y-reverse-transition">
      <v-btn
        class="extended-fab non-focused"
        color="primary"
        v-if="!isLoading && showFab && ( currentUser.is_admin || allRooms.find(room => room.class_teachers && room.class_teachers.map(ct => ct.id).includes(currentUser.id)))"
        dark
        right
        bottom
        fixed
        fab
        @click="createEditFeeDetailsSheet.visible = true"
      >
        <v-icon class="mr-1">mdi-plus</v-icon>Add New
      </v-btn>
    </transition>
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractListSheet from "@components/generics/IntractListSheet";
import moment from "moment";
import EventBus from "@utils/event_bus";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AllFeeStudents",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractCreateEditSheet,
    IntractSmartList,
    IntractListSheet,
  },
  data() {
    return {
      console,
      showFab: false,
      status: null,
      moment: moment,
      showUserSelectionSheet: false,

      createEditFeeDetailsSheet: {
        selectedUser: null,
        visible: false,
        createSuccessMessage: "Fee Details were created successfully!",
        editSuccessMessage: "Fee Details were edited successfully!",
        editId: null,
        obj: {
          institution: null,
          total_paid: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["allRooms"]),
    studentsWithoutFeeDetailsEndpoint() {
      var url = this.endpoints.studentFeesDetailViewSet + "allocation/pending/";
      if(!this.currentUser.is_admin){
        let class_teacher_rooms = this.allRooms.filter(room => room.class_teachers && room.class_teachers.map(ct => ct.id).includes(this.currentUser.id))
        if(class_teacher_rooms)
          url = this.Helper.addUrlParams(url, [`user__room__in=${ class_teacher_rooms.map(room => room.id).join(",")}`])
        else{
          this.showSnackbar({
            text: "You have not been assigned any classroom to view this section",
            type: "error",
          });
          return null

        }
      }     
      return url;
    },
    feeStudentsEndpoint() {
      var url = this.Helper.addUrlParams(
        this.endpoints.studentFeesDetailViewSet,
        "ordering=due_date,user__room__first_section__order,user__room__second_section__order,user__room__third_section__order"
      );
      
      if(!this.currentUser.is_admin){
        let class_teacher_rooms = this.allRooms.filter(room => room.class_teachers && room.class_teachers.map(ct => ct.id).includes(this.currentUser.id))
        if(class_teacher_rooms)
          url = this.Helper.addUrlParams(url, [`user__room__in=${ class_teacher_rooms.map(room => room.id).join(",")}`])
        else{
          this.showSnackbar({
            text: "You have not been assigned any classroom to view this section",
            type: "error",
          });
          return null;
        }

      } 

      if (this.status) {
        if (this.status == 1)
          return this.Helper.addUrlParams(url, "due_date__isnull=true");
        if (this.status == 2)
          return this.Helper.addUrlParams(
            url,
            `due_date__gte=${moment().format("YYYY-MM-DDThh:mm")}`
          );
        if (this.status == 3)
          return this.Helper.addUrlParams(
            url,
            `due_date__lte=${moment().format("YYYY-MM-DDThh:mm")}`
          );
      }
      return url;
    },
    filterFields() {
      return {
        status: {
          custom: true,
          model: null,
          items: [
            { title: "Not Set", value: 1 },
            { title: "Upcoming", value: 2 },
            { title: "Overdue", value: 3 },
          ],
          label: "Filter by Status",
          itemValue: "value",
          itemText: "title",
          param: "due_date",
          //   dynamicParam: true
        },
        user__room: {
          model: null,
          items: this.allRooms.filter(room => this.currentUser.is_admin || room.class_teachers && room.class_teachers.map(ct => ct.id).includes(this.currentUser.id)),
          multiple: true,
          label: "Filter by Classroom",
          // readonly: true,
          itemValue: "id",
          itemText: "sections",
          // param: "user__room__in",
          param: "user__room__in",
        },
      };
    },

    formFields() {
      return {
        user: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          label: "Select Student",
          items: [],
          helper:
            "Only the students who currently don't have fee details are displayed",
          required: true,
          md: 6,
        },
        total_fee: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          input: "number",
          min: 0,
          label: "Total Fees",
          helper: "The total amount of fees for the student",
          prependIcon: "mdi-currency-inr",
          required: true,
          md: 6,
        },
        due_date: {
          fieldType: CreateEditFieldTypes.DATEPICKER,
          textModel: null,
          min: new Date(),
          clearable:true,
          label: "Due Date",
          helper:
            "The student will start getting reminders a few days prior to this date",
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(this.createEditFeeDetailsSheet.obj.due_date).isAfter(
                moment()
              ) ||
              "Due Date should not be before current date.",
          ],
        },
      };
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),

    finalOperationsOnFormObject(obj) {
      obj.user = obj.user.id;
      if (obj.due_date) obj.due_date = moment(obj.due_date).format();
      return obj;
    },

    async initListeners() {
      EventBus.$on("student_fee_details__updated", () => {
        if (this.$refs["all-fee-students-smart-list"])
          this.onRefresh();
      });
    },

    async handleStudentSelectionClick(student, obj) {
      console.log(this.showUserSelectionSheet);
      obj["user"] = student;
      this.createEditFeeDetailsSheet.selectedUser = student;
      this.showUserSelectionSheet = false;
      console.log(this.showUserSelectionSheet);
    },
    async onRefresh() {
      var smartList = this.$refs["all-fee-students-smart-list"];
      if (smartList) smartList.getItems();
    },
    async setAppBar() {
      this.setAppBarOptions({
        title: this.appBarTitle,
        actions: [
          {
            id: 1,
            title: "All Transactions",
            icon: "mdi-currency-inr",
            onClick: () => this.$router.push({ name: "AllTransactions" }),
          },
          {
            id: 2,
            title: "Fee Payment Details",
            icon: "mdi-information-outline",
            onClick: () => this.$router.push({ name: "FeePreferences" }),
          },
        ],
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      EventBus.$emit("keep_alive__destroy_view", "AllFeeStudents");
    }
    next();
  },
  beforeDestroy() {
    this.resetAppBarOptions();
  },
  deactivated() {
    this.resetAppBarOptions();
  },
  activated() {
    this.onRefresh();
    this.setAppBar();
  },
  created() {
    this.onRefresh();
    setTimeout(() => (this.showFab = true), 500);
    this.createEditFeeDetailsSheet.obj.institution = this.currentInstitution.id;
    this.initListeners();
  },
};
</script>